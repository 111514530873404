/**
 * On older browser and devices, the `click` event is delayed a lot after the touchend event, to ensure
 * support of gestures.
 * If you want an interraction to be more reactive, and you don't need gestures, it may be interesting
 * to use this fastclick implementation instead of listening to click events
 *
 * @param element
 * @param callback
 */
export function onFastClick(
  element: HTMLElement,
  callback: (e: MouseEvent | TouchEvent) => void
) {
  let start_position_x = 0;
  let start_position_y = 0;
  element.addEventListener("touchstart", e => {
    const touch = e.changedTouches[0];
    start_position_x = touch.pageX;
    start_position_y = touch.pageY;
  });

  element.addEventListener("touchend", e => {
    const touch = e.changedTouches[0];
    if (
      // if the user move their finger more than 10px, it's a scroll, not a touch
      // 10px (squared, so 100) is arbitrary
      squaredDistanceBetween(
        { x: touch.pageX, y: touch.pageY },
        { x: start_position_x, y: start_position_y }
      ) > 100
    ) {
      return;
    }
    // Ensure the `click` event is not triggered after the touchend, preventing the callback to be executed twice
    e.preventDefault();

    callback(e);
  });

  //We support clicks for the corner case of people using a mouse with a mobile device.
  element.addEventListener("click", callback);
}

function squaredDistanceBetween(
  point_a: { x: number; y: number },
  point_b: { x: number; y: number }
) {
  return (
    Math.pow(point_a.x - point_b.x, 2) + Math.pow(point_a.y - point_b.y, 2)
  );
}
