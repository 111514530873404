import { AutocompleteOptions } from "@app/types/search-types";

import { Autocomplete } from "./autocomplete-client";
import { createAutocomplete } from "./autocomplete-factory";
import { Dropdown } from "../helpers/dropdown";

export interface Options extends AutocompleteOptions {
  dropdown: HTMLElement;
  input: HTMLInputElement;
  onShow?: () => void;
  onHide?: () => void;
  onSearchStarted?: () => void;
}

export class AutocompleteDropdown extends Dropdown {
  private id: string;
  public autocomplete: Autocomplete | null;
  private autocomplete_options: Options;

  public constructor(id: string, options: Options) {
    super({
      dropdown: options.dropdown,
      input: options.input,
      window: options.window,
      onShow: options.onShow,
      onHide: options.onHide
    });
    this.id = id;
    this.autocomplete_options = options;
    this.autocomplete = null;
  }

  public async setInputValue(value: string) {
    const autocomplete = await this._getAutocomplete();
    await autocomplete.setInputValue(value);
  }

  public async loadInitialSuggestions() {
    const autocomplete = await this._getAutocomplete();
    await autocomplete.loadInitialSuggestions();
  }

  private async _getAutocomplete(): Promise<Autocomplete> {
    if (!this.autocomplete) {
      this.autocomplete = await createAutocomplete(
        this.id,
        this.autocomplete_options
      );
    }

    return this.autocomplete;
  }
}
