function addEventListenerSafetly<T extends HTMLElement>(
  element: T | null,
  type: string,
  listener: EventListenerOrEventListenerObject
): void {
  if (element) {
    element.addEventListener(type, listener);
  }
}

function hideSafetly<T extends HTMLElement>(element: T | null): void {
  if (element) {
    element.style.display = "none";
  }
}

function showSafetly<T extends HTMLElement>(element: T | null): void {
  if (element) {
    element.style.display = "block";
  }
}

interface Options {
  dropdown: HTMLElement;
  input: HTMLInputElement;
  window?: Window;
  onShow?: () => void;
  onHide?: () => void;
}

export class Dropdown {
  public document: Document;
  public dropdown: HTMLElement;
  public dropdown_container: HTMLElement | null;
  public dropdown_close_btn: HTMLElement | null;
  public input: HTMLInputElement;
  public onShow: (() => void) | null;
  public onHide: (() => void) | null;

  public constructor(options: Options) {
    this.document = (options.window || window).document;

    // Elements
    this.dropdown = options.dropdown;
    this.input = options.input;

    this.dropdown_container = this.dropdown?.querySelector(
      ".js-dropdown-container"
    );
    this.dropdown_close_btn =
      this.dropdown?.querySelector(".js-dropdown-close");

    // Callbacks
    this.onShow = options.onShow || null;
    this.onHide = options.onHide || null;

    // Show
    const boundShow = this.show.bind(this);
    addEventListenerSafetly(this.input, "click", boundShow);
    addEventListenerSafetly(this.input, "focus", boundShow);

    // Hide
    const boundHide = this.hide.bind(this);
    addEventListenerSafetly(this.dropdown_close_btn, "click", boundHide);

    // Blur
    const boundOnBlur = this._onBlur.bind(this);
    addEventListenerSafetly(
      this.input,
      "blur",
      boundOnBlur as unknown as EventListener
    );

    // Prevent click events inside the dropdown from reaching the dropdown overlay
    // so that they don't cause the dropdown to close.
    addEventListenerSafetly(this.dropdown_container, "click", e => {
      e.stopPropagation();
    });
  }

  public show(e?: Event) {
    if (e) {
      e.stopPropagation();
    }

    showSafetly(this.dropdown_container);
    this._toggleClosingEventListener();

    if (this.onShow) {
      this.onShow();
    }
  }

  public hide() {
    hideSafetly(this.dropdown_container);
    this._toggleClosingEventListener(true);

    if (this.onHide) {
      this.onHide();
    }
  }

  private _onBlur(e: React.FocusEvent) {
    // Hide only when losing focus to an outside element (ie not clicking on the passenger dropdown)
    const is_focus_target_outside =
      e.relatedTarget && !e.relatedTarget.closest(".js-dropdown-container");

    if (is_focus_target_outside) {
      this.hide();
    }
  }

  private _toggleClosingEventListener(isActive = false) {
    const boundHide = this.hide.bind(this);

    if (isActive) {
      this.document.body.removeEventListener("click", boundHide);
      return;
    }

    addEventListenerSafetly(this.document.body, "click", boundHide);
  }
}
