import * as WindowUtils from "../../utils/window";

export interface MobileModalOptions {
  window?: Window;
  push_state_disabled?: boolean;
  onHide?: () => void;
  with_animation?: boolean;
}
class MobileModal {
  private window: Window;
  private scroll_top_before_showing: number = 0;
  private is_shown: boolean = false;
  private is_pushed_state: boolean = false;
  private push_state_disabled: boolean;
  private onHide: () => void;
  private element: HTMLElement | null;
  private with_animation: boolean;

  public constructor(element: string, options: MobileModalOptions = {}) {
    this.window = options.window || window;

    this.element = this.window.document.querySelector(element);

    this.with_animation = options.with_animation || false;

    this.push_state_disabled = options.push_state_disabled || false;
    this.onHide = options.onHide || (() => {});

    if (this.element) {
      this.element.style.display = "none";

      if (this.with_animation) {
        this.element.style.transform = "translateX(100%)";
        this.element.style.transition = "transform 0.35s";
      }
    }
    this._attachEventListeners();
  }

  private _attachEventListeners(): void {
    if (this.element) {
      this.element
        .querySelectorAll('[data-dismiss="modal"], [role="close"]')
        .forEach(element => {
          element.addEventListener("click", e => {
            e && e.preventDefault();
            this.hide();
          });
        });

      this.element.querySelectorAll(".mobile-modal-box").forEach(element => {
        element.addEventListener("click", e => {
          e && e.stopPropagation();
        });
      });
    }

    if (this._pushStateEnabled()) {
      this.window.addEventListener("popstate", () => {
        if (this.is_pushed_state && this.is_shown) {
          this.is_pushed_state = false;
          this.hide();
        }
      });
    }
  }

  private _pushStateEnabled(): boolean {
    return WindowUtils.isHistorySupported() && !this.push_state_disabled;
  }

  private _freezeBody(): void {
    this.window.document.body.style.overflow = "hidden";
    this.window.document.body.style.position = "fixed";
    this.window.document.body.style.top = `${-this
      .scroll_top_before_showing}px`;
    this.window.document.body.style.right = "0";
    this.window.document.body.style.bottom = "0";
    this.window.document.body.style.left = "0";
  }

  private _releaseBody(): void {
    this.window.document.body.style.overflow = "inherit";
    this.window.document.body.style.position = "inherit";
    this.window.document.body.style.top = "";
    this.window.document.body.style.right = "";
    this.window.document.body.style.bottom = "";
    this.window.document.body.style.left = "";

    this.window.scroll(0, this.scroll_top_before_showing);
  }

  public show(): void {
    if (this.is_shown) {
      return;
    }

    if (this._pushStateEnabled()) {
      window.history.pushState({}, "", window.location.href);
      this.is_pushed_state = true;
    }

    this.scroll_top_before_showing = this.window.scrollY || 0;

    this.is_shown = true;

    setTimeout(() => {
      if (this.element) {
        const container = this.element.querySelector<HTMLElement>(
          ".mobile-modal-container"
        );
        if (container) {
          container.style.opacity = "1";
        }
        this.element.style.display = "block";

        if (this.with_animation) {
          // A timeout is needed when using transition right after changing `display: none` property
          // c.f https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Transitions/Using_CSS_transitions#javascript_examples
          setTimeout(() => {
            if (this.element) {
              this.element.style.transform = "translateX(0)";
            }
          }, 50);
        }
      }

      // freezeBody forces a repaint of whole document.
      // If we do it synchronously, it impacts negatively our INP
      setTimeout(() => {
        this._freezeBody();
        this.scrollToTop();
      });
    });
  }

  private scrollToTop() {
    this.window.scroll(0, 0);
    // XXX The line below was required to fix a scrolling issue with iPhone5/SE: https://github.com/busbud/public-website/issues/5011
    if (typeof this.window.document.body.scroll === "function") {
      this.window.document.body.scroll(0, 0);
    }
  }

  public hide(): void {
    if (!this.is_shown) {
      return;
    }

    if (this.is_pushed_state) {
      window.history.back();
      this.is_pushed_state = false;
    }

    this.is_shown = false;

    // remove iOS keyboard
    // https://stackoverflow.com/a/7761438
    if (this.window.document.activeElement instanceof HTMLElement) {
      this.window.document.activeElement?.blur();
    }
    if (this.element) {
      this.element.style.display = "none";

      if (this.with_animation) {
        this.element.style.transform = "translateX(100%)";
      }
    }
    this._releaseBody();

    this.onHide();
  }
}

export default MobileModal;
