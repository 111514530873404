/**
 * Mirror implementation of lodash.bindAll
 *
 * @param this_arg
 * @param {...any} function_names
 */
export default function bindAll<T>(
  this_arg: T,
  ...function_names: (keyof T)[]
): void {
  if (this_arg && function_names.length) {
    function_names.forEach(f => {
      const func = this_arg[f];
      if (typeof func === "function") {
        this_arg[f] = func.bind(this_arg);
      }
    });
  }
}
