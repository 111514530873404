import { noop } from "lodash";

import { AutocompleteOptions, SearchPlace } from "@app/types/search-types";

import { AutocompleteDropdown } from "./autocomplete/autocomplete-dropdown-client";
import { styleDsElement } from "./helpers/styleDsElement";
import SearchFormClient, {
  SearchFormClientOptions
} from "./search-form-client";
import { setOutboundDate } from "./store/slices/search-form";
import { openModal, closeModal } from "../../store/state/modals";
import * as Events from "../../tracking/search-tracking";

export default class SearchFormDesktopClient extends SearchFormClient {
  public _toggleVisibilityCallback: () => void;
  public constructor(options: SearchFormClientOptions) {
    super(options);

    this._handleOriginSelected = this._handleOriginSelected.bind(this);
    this._handleDestinationSelected =
      this._handleDestinationSelected.bind(this);

    this._toggleVisibilityCallback = noop;

    this._initForm();
    this._initOriginInput();
    this._initDestinationInput();
    this._initOutboundDateInput();
    this._initReturnDateInput();
    this._initPassengerInput();

    this._enableFormOnLoad();
  }

  /**
   * Attach an event listener for search form visibility toggle
   *
   * @param cb
   */
  public onToggleVisibility(cb: () => void) {
    this._toggleVisibilityCallback = cb;
  }

  public changeOutboundDate(date: string | null) {
    this.window.store.dispatch(setOutboundDate(date));
  }

  protected override async _initOriginInput() {
    const autocomplete_options = {
      append_to: "#origin-dropdown-wrapper .js-suggestions-container",
      autoselectOnBlur: true,
      features: this.options.features,
      lang: this.options.lang,
      latitude: this.state.latitude,
      locale: this.options.locale,
      longitude: this.state.longitude,
      napi_url: this.options.napi_url,
      tracker: this.tracker,
      whitelabel: this.options.whitelabel,
      onPlaceSelected: (place: SearchPlace) =>
        this._handleOriginSelected(place),
      onSearchStarted: this.handleSearchStarted.bind(this),
      onSearchEnded: this.handleSearchEnded.bind(this)
    };

    const dropdown = this.window.document.getElementById(
      "origin-dropdown-wrapper"
    ) as HTMLElement;

    this.origin_autocomplete = new AutocompleteDropdown("origin-city", {
      dropdown,
      window: this.window,
      input: this.origin_city_input,
      ...autocomplete_options
    });

    if (this.origin_autocomplete) {
      void this.origin_autocomplete.loadInitialSuggestions();
    }
    const hash = this.window.BB.inst.request_id;
    this.origin_city_input.setAttribute("name", hash); // TODO: remove once chrome starts to obey autocomplete=off attr

    styleDsElement(this.origin_city_input);

    this.origin_city_input.addEventListener("focus", e => {
      e.preventDefault();
      this._closeCalendarAndPassengerDropdowns();
      this.origin_city_input.select();
      this.tracker.asyncTrack(Events.clickedSearchInput("origin"));
    });

    super._initOriginInput();
  }

  protected override async _initDestinationInput(): Promise<void> {
    const autocomplete_options: AutocompleteOptions = {
      append_to: "#destination-dropdown-wrapper .js-suggestions-container",
      autoselectOnBlur: true,
      features: this.options.features,
      lang: this.options.lang,
      latitude: this.state.latitude,
      locale: this.options.locale,
      longitude: this.state.longitude,
      napi_url: this.options.napi_url,
      tracker: this.tracker,
      whitelabel: this.options.whitelabel,
      onPlaceSelected: suggested_place =>
        this._handleDestinationSelected(suggested_place),
      onSearchStarted: this.handleSearchStarted.bind(this),
      onSearchEnded: this.handleSearchEnded.bind(this)
    };

    const dropdown = this.window.document.getElementById(
      "destination-dropdown-wrapper"
    ) as HTMLElement;

    this.destination_autocomplete = new AutocompleteDropdown(
      "destination-city",
      {
        dropdown,
        window: this.window,
        input: this.destination_city_input,
        ...autocomplete_options
      }
    );

    const hash = this.window.BB.inst.request_id;
    if (this.destination_autocomplete) {
      void this.destination_autocomplete.loadInitialSuggestions();
    }

    this.destination_city_input.setAttribute("name", hash); // TODO: remove once chrome starts to obey autocomplete=off attr

    styleDsElement(this.destination_city_input);

    this.destination_city_input.addEventListener("focus", e => {
      e.preventDefault();
      this._closeCalendarAndPassengerDropdowns();
      this.destination_city_input.select();
      this.tracker.asyncTrack(Events.clickedSearchInput("destination"));
    });

    super._initDestinationInput();
  }

  // Algolia Autocomplete set listeners on origin & destination inputs. These
  // listener are stopping event propagation, and by doing so disable the
  // clickAway callback. Thus, calendar and passenger selector dropdowns are
  // not closed when users click on a location input.
  // This helper force-closes these 2 dropdowns
  protected _closeCalendarAndPassengerDropdowns() {
    this.window.store.dispatch(closeModal("all"));
  }

  protected override _initOutboundDateInput() {
    super._initOutboundDateInput();
    styleDsElement(this.outbound_date_input);
  }

  protected override _initReturnDateInput() {
    super._initReturnDateInput();
    styleDsElement(this.return_date_input);
  }

  private _initPassengerInput() {
    this.passenger_input.addEventListener("click", e => {
      e.preventDefault();
      this.window.store.dispatch(
        openModal({
          type: "passengers_selector",
          input_element: this.passenger_input,
          close_existing: true
        })
      );

      this.tracker.asyncTrack(Events.clickedSearchPassenger());
    });

    // Used to handle the change of Server-side rendered Passenger Input
    this._onStateChange(
      state => state.passengers,
      (_, passengers) => {
        this._handlePassengerInputChange.call(this, passengers);
      }
    );
  }

  public override _redirectToResultsPage() {
    super._redirectToResultsPage();
  }

  public override async _handleOriginSelected(
    place: SearchPlace
  ): Promise<void> {
    super._handleOriginSelected(place);

    void this.destination_autocomplete?.loadInitialSuggestions();
    this.origin_city_input.classList.remove("has-errors");

    this.tracker.asyncTrack(Events.selectedOrigin(place));

    this.destination_city_input.focus();
  }

  public override _handleDestinationSelected(place: SearchPlace): void {
    super._handleDestinationSelected(place);

    this.destination_city_input.classList.remove("has-errors");

    this.tracker.asyncTrack(Events.selectedDestination(place));

    this.outbound_date_input.focus();
  }
}
