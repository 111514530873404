import MobileModal, {
  MobileModalOptions
} from "@app/components/mobile-modal/mobile-modal-client";
import type { Autocomplete } from "@app/modules/search/autocomplete/autocomplete-client";
import { AutocompleteOptions } from "@app/types/search-types";

import { createAutocomplete } from "./autocomplete-factory";

export class AutocompleteModal {
  private id: string;
  private autocomplete: Autocomplete | null;
  private autocomplete_options: AutocompleteOptions;
  private modal: MobileModal;
  public constructor(
    id: string,
    autocomplete_options: AutocompleteOptions,
    modal_options?: MobileModalOptions
  ) {
    this.id = id;
    this.autocomplete = null;
    this.autocomplete_options = autocomplete_options;
    this.modal = new MobileModal(`#${id}-modal`, modal_options);
  }

  public async show() {
    this.modal.show();
    const autocomplete = await this._getAutocomplete();
    autocomplete.focus();
  }

  public hide() {
    this.modal.hide();
  }

  public async destroy() {
    const autocomplete = await this._getAutocomplete();
    autocomplete.destroy();
  }

  public getAutocomplete() {
    this._getAutocomplete();
  }

  public async setInputValue(value: string) {
    const autocomplete = await this._getAutocomplete();
    autocomplete.setInputValue(value);
  }

  public async loadInitialSuggestions() {
    const autocomplete = await this._getAutocomplete();
    autocomplete.loadInitialSuggestions();
  }

  private async _getAutocomplete(): Promise<Autocomplete> {
    if (!this.autocomplete) {
      this.autocomplete = await createAutocomplete(
        this.id,
        this.autocomplete_options
      );
    }

    return this.autocomplete;
  }
}
