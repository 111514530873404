interface Options {
  element: HTMLElement;
  disabled: boolean;
  onClick?: (e: Event) => void;
}

export class SwapCities {
  public element: HTMLElement;
  public onClick: ((e: Event) => void) | undefined;

  public constructor(options: Options) {
    this.element = options.element;
    this.onClick = options.onClick;

    if (options.disabled) {
      return;
    }

    this.element.addEventListener("click", e => {
      e && e.preventDefault();
      if (this.onClick) {
        this.onClick(e);
      }
    });
  }
}
