import { isEqual } from "lodash";
import { Unsubscribe } from "redux";

import { VehicleType } from "@busbud/int-schemas";

import { LocalStorage } from "@app/lib/bowser-storage/local-storage";
import { onFastClick } from "@app/modules/search/fast-click";
import type { Tracker } from "@app/tracking/tracker";
import { Features } from "@app/types/experiments";
import {
  PassengerBaseCount,
  SearchFormMergedState,
  SearchFormState,
  SearchPlace,
  SearchReduxState
} from "@app/types/search-types";
import {
  formatToUtcDate,
  getTomorrowDateFormatted,
  getUTCDate,
  setupLocalizedDateGetter
} from "@app/utils/dates-without-moment";

import { landing_pages_entities_trains_types } from "@data/entity-types";
import { Whitelabel } from "@partners/partner.type";

import { AutocompleteDropdown } from "./autocomplete/autocomplete-dropdown-client";
import { AutocompleteModal } from "./autocomplete/autocomplete-modal-client";
import {
  AUTOCOMPLETE_SPINNER_DELAY_MS,
  SEARCH_ERRORS,
  VALIDATE_FORM_MESSAGE
} from "./constants";
import DomSelector from "./dom-selector";
import { placeToSuggestion } from "./helpers";
import { getPassengersTranslationAttr } from "./helpers/passenger-input";
import { saveRecentSearch } from "./helpers/recent-searches";
import { getSearchQuery } from "./helpers/submit";
import { getResultsPageUrl, getSearchBookingComQuery } from "./helpers/submit";
import { SwapCities } from "./swap-cities/swap-cities";
import { getFormattedSuggestionName } from "../../components/search-form/helpers";
import {
  AffiliatePartners,
  BOOKING_COM_AID
} from "../../constants/affiliate-partners";
import {
  areChildrenAccompanied,
  isMissingAges,
  getLegacySearchFormPassengers
} from "../../modules/search/store/selectors/passengers";
import IntlProvider from "../../services/intl-provider";
import {
  setAffiliateOptin,
  setDestination,
  setOrigin,
  setOutboundDate,
  setRecentDestination,
  setRecentOrigin,
  setReturnDate
} from "./store/slices/search-form";
import { openModal } from "../../store/state/modals";
import * as Events from "../../tracking/search-tracking";
import * as WindowUtils from "../../utils/window";

export interface SearchFormClientOptions {
  napi_url: string;
  lang: string;
  locale: string;
  initial_state: SearchFormMergedState;
  features: Partial<Features>;
  tracker: Tracker;
  push_state_disabled?: boolean;
  whitelabel: Whitelabel;
  window?: Window;
  discount_code?: string | undefined;
}

export default class SearchFormClient {
  // Only public for test
  public window: Window;
  protected tracker: Tracker;
  protected features: Partial<Features>;
  private push_state_disabled: boolean;
  protected all_passenger_inputs: null;
  private initial_origin: SearchReduxState["origin"];
  private initial_destination: SearchReduxState["destination"];
  protected state: SearchFormState;
  protected form_element: HTMLFormElement;
  private submit_button_element: HTMLFormElement;
  private submit_button_element_touched: boolean;
  protected origin_city_input: HTMLInputElement;
  protected origin_city_input_touched: boolean;
  protected destination_city_input: HTMLInputElement;
  protected destination_city_input_touched: boolean;
  protected swap_cities: SwapCities;
  protected outbound_date_input: HTMLInputElement;
  protected outbound_date_value: HTMLInputElement;
  protected return_date_input: HTMLInputElement;
  private return_date_value: HTMLInputElement;
  protected passenger_input: HTMLInputElement;
  protected origin_autocomplete:
    | null
    | AutocompleteModal
    | AutocompleteDropdown;
  protected destination_autocomplete:
    | null
    | AutocompleteModal
    | AutocompleteDropdown;
  private affiliate_checkbox: HTMLInputElement;
  private _getLocalizedDate: undefined | ((date: string) => string | null) =
    undefined;
  private _spinner_timer: number | null = null;

  public constructor(protected options: SearchFormClientOptions) {
    this.options = options;
    // Use given window as context to cut the dependency to DOM so we can test.
    this.window = options.window || window;
    this.tracker = options.tracker;
    this.features = options.features;
    this.push_state_disabled = options.push_state_disabled || false;
    this.all_passenger_inputs = null;

    this.submit_button_element_touched = false;
    this.origin_city_input_touched = false;
    this.destination_city_input_touched = false;

    const { origin, destination, affiliate_optin } = this._getSearchFormState();
    this.initial_origin = origin;
    this.initial_destination = destination;

    this.state = { ...options.initial_state };

    // Use DomSelector abstraction so we always get a valid HTMLElement out of the getters.
    const dom_selector = new DomSelector(this.window.document);

    this.form_element = this.window.document.querySelector(
      "#search-form"
    ) as HTMLFormElement;
    this.submit_button_element = this.window.document.querySelector(
      "#search-submit-button"
    ) as HTMLFormElement;
    this.origin_city_input = dom_selector.getInputElement("#origin-city-input");
    this.destination_city_input = dom_selector.getInputElement(
      "#destination-city-input"
    );
    this.swap_cities = new SwapCities({
      element: dom_selector.getElement("#swap-cities-icon"),
      disabled:
        this.window.BB.entity?.type === "results" &&
        !!this.features.SEARCH_FORM_DISABLE_LOCATION_INPUTS_ON_RESULTS,
      onClick: () => {
        this._handleSwapCities();
      }
    });

    this.outbound_date_input = dom_selector.getInputElement(
      "#outbound-date-input"
    );

    this.outbound_date_value = dom_selector.getInputElement(
      "#outbound-date-value"
    );
    this.return_date_input = dom_selector.getInputElement("#return-date-input");
    this.return_date_value = dom_selector.getInputElement("#return-date-value");
    this.passenger_input = dom_selector.getInputElement("#passenger-input");
    this.origin_autocomplete = null;
    this.destination_autocomplete = null;
    this.affiliate_checkbox = dom_selector.getInputElement(
      "#affiliate_checkbox input, input#affiliate_checkbox"
    );

    if (
      !dom_selector.isNoopElement(this.affiliate_checkbox) &&
      affiliate_optin
    ) {
      this._initAffiliateCheckbox();
    }

    if (this._shouldUseLocalizedDate()) {
      this._getLocalizedDate = setupLocalizedDateGetter(
        new IntlProvider({
          locale: options.locale,
          // Currency doesn't really matter here. It's required to construct valid IntlProvider though.
          // It means our IntlProvider has too many responsibilities, we're only using a few here.
          currency: "USD"
        })
      );
    }
  }

  /**
   * Function that merges the Redux state and the class state.
   * This should be used instead of this.state when we want to access a value of the state.
   *
   * Public only for tests
   *
   * @returns
   */
  public _getMergedState(): SearchFormMergedState {
    const redux_state = this._getSearchFormState();

    const passengers = getLegacySearchFormPassengers({
      search_form: redux_state
    });

    return { ...this.state, ...redux_state, ...passengers };
  }

  /**
   * Function that returns the part of the search form state stored in Redux
   *
   * @returns SearchReduxState
   */
  protected _getSearchFormState(): SearchReduxState {
    return this.window.store.getState().search_form;
  }

  /**
   * Function that returns true when the search form state is different than the search
   *
   * @returns boolean
   */
  protected _isSearchFormStateDifferentFromSearch(): boolean {
    const { search, search_form } = this.window.store.getState();

    return !(
      search &&
      search.origin === search_form.origin?.city?.geohash &&
      search.destination === search_form.destination?.city?.geohash &&
      search.outbound_date === search_form.outbound_date &&
      search.return_date ===
        (search_form.return_date ? search_form.return_date : null) &&
      search.adult === search_form.passengers.adult &&
      search.adult_student === search_form.passengers.adult_student &&
      search.adult_wheelchair === search_form.passengers.adult_wheelchair &&
      search.child === search_form.passengers.child &&
      search.child_student === search_form.passengers.child_student &&
      isEqual(
        search.child_student_ages,
        search_form.passengers.child_student_ages
      ) &&
      search.child_wheelchair === search_form.passengers.child_wheelchair &&
      isEqual(
        search.child_wheelchair_ages,
        search_form.passengers.child_wheelchair_ages
      ) &&
      search.senior === search_form.passengers.senior &&
      search.senior_student === search_form.passengers.senior_student &&
      isEqual(
        search.senior_student_ages,
        search_form.passengers.senior_student_ages
      ) &&
      search.senior_wheelchair === search_form.passengers.senior_wheelchair &&
      isEqual(
        search.senior_wheelchair_ages,
        search_form.passengers.senior_wheelchair_ages
      )
    );
  }

  protected _updateSearchButton(): void {
    const different_from_search = this._isSearchFormStateDifferentFromSearch();

    const primary = [
      "bg-color-scheme-brand-primary-500",
      "shadow-sm",
      "hover:bg-color-scheme-brand-primary-400",
      "hover:shadow-sm",
      "active:bg-color-scheme-brand-primary-600",
      "[&:not(:focus-visible)]:active:shadow-none",
      "text-color-primary-inverse",
      "hover:text-color-primary-inverse",
      "active:text-color-primary-inverse"
    ];
    const primary_icon = "text-icon-color-primary-inverse";

    const transparent = [
      "bg-color-static-transparent",
      "hover:bg-color-glass-secondary",
      "active:bg-color-glass-tertiary",
      "text-color-primary"
    ];
    const transparent_icon = "text-icon-color-primary";

    const buttons = this.submit_button_element.querySelectorAll("button");

    if (different_from_search) {
      buttons.forEach(button => {
        button.classList.remove(...transparent);
        button.classList.add(...primary);

        button.querySelector("span")?.classList.add(primary_icon);
        button.querySelector("span")?.classList.remove(transparent_icon);
      });
    } else {
      buttons.forEach(button => {
        button.classList.remove(...primary);
        button.classList.add(...transparent);

        button.querySelector("span")?.classList.remove(primary_icon);
        button.querySelector("span")?.classList.add(transparent_icon);
      });
    }
  }

  protected _onStateChange<T>(
    selector: (state: SearchReduxState) => T,
    onChange: (prev: T, next: T) => void
  ): Unsubscribe {
    let prev_state = selector(this._getSearchFormState());
    const handleChange = () => {
      const next_state = selector(this._getSearchFormState());
      if (next_state !== prev_state) {
        onChange(prev_state, next_state);
        if (this.features.DYNAMIC_SEARCH_BUTTON) {
          this._updateSearchButton();
        }
        prev_state = next_state;
      }
    };

    return this.window.store.subscribe(handleChange);
  }

  protected _initForm() {
    this.origin_city_input.addEventListener("touchend", () => {
      this.origin_city_input_touched = true;
    });
    this.destination_city_input.addEventListener("touchend", () => {
      this.destination_city_input_touched = true;
    });

    if (this.submit_button_element) {
      onFastClick(this.submit_button_element, async e => {
        e.preventDefault();
        this.submit_button_element_touched = true;
        await this._handleFormSubmit();
      });
    }
    // use a messaging system to communicate with react part of the system
    window.addEventListener("message", event => {
      if (event.data === VALIDATE_FORM_MESSAGE) {
        this._validateForm();
      }
    });
  }

  private _handleFormSubmit() {
    const {
      affiliate_optin,
      origin,
      destination,
      outbound_date,
      return_date,
      recent_searches,
      adult,
      child,
      senior,
      adult_wheelchair,
      child_wheelchair,
      senior_wheelchair
    } = this._getMergedState();

    const errors = this._validateForm();

    if (!(origin && destination && outbound_date)) {
      return;
    }

    const browser_storage = LocalStorage.fromWindow(this.window);

    saveRecentSearch(browser_storage, "origin", recent_searches.origin);
    saveRecentSearch(
      browser_storage,
      "destination",
      recent_searches.destination
    );

    const search_with_accomodation_affiliate = affiliate_optin
      ? AffiliatePartners.BOOKING_COM
      : "none";

    this.tracker.track(
      Events.searchedRoute({
        state: {
          origin,
          destination,
          outbound_date,
          return_date,
          adult,
          child,
          senior,
          adult_wheelchair,
          child_wheelchair,
          senior_wheelchair
        },
        initial_origin: this.initial_origin,
        initial_destination: this.initial_destination,
        search_with_accomodation_affiliate
      })
    );

    if (errors.length) {
      this.tracker.asyncTrack(
        Events.failedRouteSearch({
          state: {
            origin,
            destination,
            outbound_date,
            return_date,
            adult,
            child,
            senior,
            adult_wheelchair,
            child_wheelchair,
            senior_wheelchair
          },
          errors,
          search_with_accomodation_affiliate
        })
      );

      if (errors.includes(SEARCH_ERRORS.MISSING_AGE)) {
        // open passenger selector modal so user can see the erroneous field
        this.window.store.dispatch(
          openModal({
            type: "passengers_selector",
            input_element: this.passenger_input,
            close_existing: true
          })
        );
      }

      return;
    }

    if (!this.push_state_disabled) {
      // This allows us to fill the form when the user clicks back from results page
      WindowUtils.appendQueryToCurrentLocation(
        getSearchQuery(this.options, this._getMergedState())
      );
    }

    if (search_with_accomodation_affiliate === AffiliatePartners.BOOKING_COM) {
      this._ancillaryRedirectAffiliateAndShowBusbudResults(
        AffiliatePartners.BOOKING_COM
      );
      return;
    }

    this._redirectToResultsPage();
  }

  protected _ancillaryRedirectAffiliateAndShowBusbudResults(
    affiliate_name: AffiliatePartners
  ): void | Promise<void> {
    const getQuery = getSearchBookingComQuery;
    const affiliate_data = { aid: BOOKING_COM_AID.SEARCH };
    const query_data = {
      ...this._getMergedState(),
      ...affiliate_data
    };

    // open new window for busbud on focus
    return this._openToResultsPage()
      .then(() => {
        const url = getQuery(query_data);
        this.tracker.track(
          Events.redirectToAffiliatePartner(
            affiliate_name,
            "searchbox_pop_under"
          ),
          () => {
            WindowUtils.redirect(url);
          }
        );
      })
      .catch(() => {
        this._redirectToResultsPage();
      });
  }

  private _validateForm(): string[] {
    const errors: string[] = [];

    const search_form_state = this._getSearchFormState();
    const { origin, destination, outbound_date, return_date } =
      search_form_state;

    if (
      (this.origin_city_input_touched || this.submit_button_element_touched) &&
      (!origin || !origin.city.geohash)
    ) {
      this._addErrorStyle(this.origin_city_input);
      errors.push(SEARCH_ERRORS.MISSING_ORIGIN);
    } else {
      this._removeErrorStyle(this.origin_city_input);
    }

    if (
      (this.destination_city_input_touched ||
        this.submit_button_element_touched) &&
      (!destination || !destination.city.geohash)
    ) {
      this._addErrorStyle(this.destination_city_input);
      errors.push(SEARCH_ERRORS.MISSING_DESTINATION);
    } else {
      this._removeErrorStyle(this.destination_city_input);
    }

    if (!outbound_date) {
      this._addErrorStyle(this.outbound_date_input);
      errors.push(SEARCH_ERRORS.MISSING_OUTBOUND);
    } else {
      this._removeErrorStyle(this.outbound_date_input);
    }

    if (outbound_date && return_date) {
      if (new Date(return_date) < new Date(outbound_date)) {
        this._addErrorStyle(this.return_date_input);
        errors.push(SEARCH_ERRORS.RETURN_BEFORE_OUTBOUND);
      } else {
        this._removeErrorStyle(this.return_date_input);
      }
    }

    if (!areChildrenAccompanied({ search_form: search_form_state })) {
      errors.push(SEARCH_ERRORS.CHILD_ALONE);
    }

    if (isMissingAges({ search_form: search_form_state })) {
      errors.push(SEARCH_ERRORS.MISSING_AGE);
      this._addErrorStyle(this.passenger_input);
    } else {
      this._removeErrorStyle(this.passenger_input);
    }

    return errors;
  }

  /**
   * Get the closest parent element that has data-dsclassmap attribute.
   * This attributes contains mapping of SSR generated class names (e.g `header-DsButton-label-181`)
   * with human readeble class names (e.g `error`)
   *
   * @param element
   **/
  private _getParentElementWithDSCClassMap(element: Element) {
    return element.closest("[data-dsclassmap]");
  }

  private _getErrorClasses(element: Element): string[] | undefined {
    try {
      const dscl_class_map = JSON.parse(
        element.getAttribute("data-dsclassmap") || "{}"
      ) as Record<string, string[]>;
      return dscl_class_map["error"];
    } catch {
      return;
    }
  }

  private _addErrorStyle(element: Element) {
    const parent_element = this._getParentElementWithDSCClassMap(element);
    if (parent_element) {
      const error_classes = this._getErrorClasses(parent_element);
      if (error_classes?.length) {
        error_classes.forEach(error_class => {
          parent_element.classList.add(error_class);
        });
      }
    }
  }

  private _removeErrorStyle(element: Element) {
    const parent_element = this._getParentElementWithDSCClassMap(element);
    if (parent_element) {
      const error_classes = this._getErrorClasses(parent_element);
      if (error_classes?.length) {
        error_classes.forEach(error_class => {
          parent_element.classList.remove(error_class);
        });
      }
    }
  }

  // Public for tests
  public _redirectToResultsPage(): void {
    let vehicle_category: VehicleType | undefined = undefined;

    if (
      this.window.BB.entity?.type &&
      landing_pages_entities_trains_types.includes(this.window.BB.entity.type)
    ) {
      vehicle_category = VehicleType.Train;
    }
    const state = this._getMergedState();

    if (vehicle_category) {
      state.vehicle_category = vehicle_category;
    }

    const results_page_url = getResultsPageUrl(this.options, state);
    WindowUtils.redirect(results_page_url);
  }

  // Public only for tests
  public _openToResultsPage(): Promise<void> {
    return new Promise((resolve, reject) => {
      const results_page_url = getResultsPageUrl(
        this.options,
        this._getMergedState()
      );
      try {
        WindowUtils.openAndFocusOn(results_page_url);
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  }

  // These methods are overriden in subclasses
  protected _initOriginInput() {
    this._onStateChange(
      state => state.origin,
      () => {}
    );
  }
  protected _initDestinationInput() {
    this._onStateChange(
      state => state.destination,
      () => {}
    );
  }

  public isSamePlace(place1: SearchPlace, place2: SearchPlace): boolean {
    if (place1.location && place2.location) {
      // if both are locations
      return place1.location.full_name === place2.location.full_name;
    }

    if (place1.location || place2.location) {
      // if only one of them is a location
      return false;
    }

    return place1.city.id === place2.city.id;
  }

  // Only public for tests
  public _handleOriginSelected(place: SearchPlace): void {
    const { origin } = this._getSearchFormState();

    if (origin && this.isSamePlace(place, origin)) {
      return; // if user selects the city OR the location already in state, skip sync
    }

    this.window.store.dispatch(setOrigin(place));

    this._validateForm();
  }

  // Only public for tests
  // eslint-disable-next-line no-unused-vars
  public _handleDestinationSelected(
    place: SearchPlace,
    _autocomplete_type?: string,
    _suggestion?: unknown
  ): void {
    const { destination } = this._getSearchFormState();

    if (destination && this.isSamePlace(place, destination)) {
      return;
    }

    this.window.store.dispatch(setDestination(place));

    this._validateForm();
  }

  // Only public for tests
  public _handleSwapCities(): void {
    const { origin, destination, recent_searches } = this._getSearchFormState();

    this.window.store.dispatch(setOrigin(destination));
    this.window.store.dispatch(setDestination(origin));

    this.window.store.dispatch(setRecentOrigin(recent_searches.destination));
    this.window.store.dispatch(setRecentDestination(recent_searches.origin));

    if (this.origin_autocomplete) {
      this.origin_autocomplete.setInputValue(
        getFormattedSuggestionName(placeToSuggestion(destination))
      );
    }

    if (this.destination_autocomplete) {
      this.destination_autocomplete.setInputValue(
        getFormattedSuggestionName(placeToSuggestion(origin))
      );
    }

    this.tracker.asyncTrack(Events.clickedReverseCities(origin, destination));
  }

  protected _initOutboundDateInput(): void {
    onFastClick(this.outbound_date_input, e => {
      e.preventDefault();
      const { outbound_date } = this._getSearchFormState();
      // Let the clickAway event propagate before the new modal
      setTimeout(() => {
        this.window.store.dispatch(
          openModal({
            type: "calendar",
            selected_date: outbound_date,
            direction: "outbound",
            input_element: this.outbound_date_input,
            close_existing: true,
            display_loading_placeholder: this.window.BB.device.is_mobile
          })
        );
      }, 0);

      this.tracker.asyncTrack(Events.clickedSearchDate("outbound"));
    });

    this._onStateChange(
      state => state.outbound_date ?? undefined,
      this._handleOutboundDateChanged.bind(this)
    );
  }

  private _handleOutboundDateChanged(
    prev_date?: string,
    next_date?: string
  ): void {
    if (!next_date) {
      return;
    }

    this.outbound_date_value.value = next_date;
    this.outbound_date_value.classList.remove("has-errors");

    if (this._shouldUseLocalizedDate() && this._getLocalizedDate) {
      this.outbound_date_input.value = this._getLocalizedDate(next_date) ?? "";
    }

    if (this._shouldIncreaseReturnDate(next_date)) {
      const utc_date = getUTCDate(next_date);
      utc_date.setDate(utc_date.getDate() + 1);
      const formatted_date = formatToUtcDate(utc_date);

      this.window.store.dispatch(setReturnDate(formatted_date));
    }

    if (prev_date && prev_date !== next_date) {
      this.tracker.asyncTrack(
        Events.selectedDepartureDate(prev_date, next_date)
      );
    }
  }

  protected _initReturnDateInput(): void {
    if (!this.return_date_input.parentNode) {
      return;
    }

    onFastClick(this.return_date_input, e => {
      e.preventDefault();
      const { return_date } = this._getSearchFormState();
      // Let the clickAway event propagate before the new modal
      setTimeout(() => {
        this.window.store.dispatch(
          openModal({
            type: "calendar",
            selected_date: return_date,
            direction: "return",
            input_element: this.return_date_input,
            close_existing: true,
            display_loading_placeholder: this.window.BB.device.is_mobile
          })
        );
      }, 0);

      this.tracker.asyncTrack(Events.clickedSearchDate("return"));
    });

    this._onStateChange(
      state => state.return_date ?? undefined,
      this._handleReturnDateChanged.bind(this)
    );
  }

  private _handleReturnDateChanged(
    prev_date?: string,
    next_date?: string
  ): void {
    this.return_date_value.classList.remove("has-errors");

    if (!next_date) {
      this.return_date_value.value = "";
      this.return_date_input.value = "";
      this.tracker.asyncTrack(Events.unselectedReturnDate());
    } else {
      this.return_date_value.value = next_date;

      if (this._shouldUseLocalizedDate() && this._getLocalizedDate) {
        this.return_date_input.value = this._getLocalizedDate(next_date) ?? "";
      }

      this.tracker.asyncTrack(
        Events.selectedReturnDate(prev_date ?? null, next_date)
      );
    }
  }

  // Public only for test
  public _shouldUseLocalizedDate(): boolean {
    // Only for modern browsers with `Intl`
    return typeof Intl !== "undefined";
  }

  protected _handlePassengerInputChange(passengers: PassengerBaseCount): void {
    const passenger_count =
      passengers.adult + passengers.child + passengers.senior;

    this.passenger_input.value =
      document
        .getElementById("total-passengers-input")
        ?.getAttribute(getPassengersTranslationAttr(passenger_count))
        ?.replace("[num-placeholder]", String(passenger_count)) || "";
  }

  private _shouldIncreaseReturnDate(new_outbound_date: string): boolean {
    const { return_date } = this._getSearchFormState();
    if (!return_date) {
      return false;
    }

    const outbound_date = getUTCDate(new_outbound_date);

    return outbound_date > getUTCDate(return_date);
  }

  // Remove disabled classes to indicate that the form is ready to use.
  // public for tests
  public _enableFormOnLoad(): void {
    setTimeout(() => {
      this.form_element
        ?.querySelectorAll(".form-input, .btn, .label-checkbox")
        .forEach(item => {
          item.classList.remove("disabled");
        });

      if (!this._getSearchFormState().outbound_date) {
        this.window.store.dispatch(setOutboundDate(getTomorrowDateFormatted()));
      }
    });
  }

  private _initAffiliateCheckbox(): void {
    if (!this.affiliate_checkbox) {
      return;
    }

    this.affiliate_checkbox.addEventListener(
      "change",
      this._handleAffiliateCheckboxChange.bind(this)
    );
  }

  private _handleAffiliateCheckboxChange(evt: Event): void {
    const checkbox = evt.currentTarget as HTMLInputElement;
    const new_checkbox_state = !!checkbox.checked;

    this.window.store.dispatch(setAffiliateOptin(new_checkbox_state));
    checkbox.checked = new_checkbox_state;

    if (!checkbox.parentElement) {
      return;
    }

    if (checkbox.checked) {
      checkbox.parentElement.setAttribute("data-checked", "true");
    } else {
      checkbox.parentElement.removeAttribute("data-checked");
    }

    this.tracker.asyncTrack(
      Events.clickedAffiliateCheckbox(
        AffiliatePartners.BOOKING_COM,
        checkbox.checked
      )
    );
  }

  public handleSearchStarted(): void {
    this._spinner_timer = this.window.setTimeout(() => {
      const spinners = this.window.document.querySelectorAll<HTMLElement>(
        ".js-suggestions-loading-spinner"
      );
      spinners?.forEach(spinner => {
        spinner.style.display = "block";
      });
    }, AUTOCOMPLETE_SPINNER_DELAY_MS);
  }

  public handleSearchEnded(): void {
    if (this._spinner_timer) {
      clearTimeout(this._spinner_timer);
    }
    const spinners = this.window.document.querySelectorAll<HTMLElement>(
      ".js-suggestions-loading-spinner"
    );
    spinners?.forEach(spinner => {
      spinner.style.display = "none";
    });
  }
}
