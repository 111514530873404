import { onFastClick } from "@app/modules/search/fast-click";
import { AutocompleteOptions, SearchPlace } from "@app/types/search-types";
import { AmplitudeEvent } from "@app/types/tracking/common";
import {
  ClickedSearchDestination,
  ClickedSearchOrigin
} from "@app/types/tracking/search";

import { AutocompleteModal } from "./autocomplete/autocomplete-modal-client";
import { placeToSuggestion } from "./helpers";
import SearchFormClient, {
  SearchFormClientOptions
} from "./search-form-client";
import { setOutboundDate } from "./store/slices/search-form";
import { getFormattedSuggestionName } from "../../components/search-form/helpers";
import { openModal } from "../../store/state/modals";
import * as Events from "../../tracking/search-tracking";
import bindAll from "../../utils/bind";

export default class SearchFormMobileClient extends SearchFormClient {
  public _toggleVisibilityCallback: () => void;
  private toggle_search_btn: NodeListOf<Element> | null = null;
  public constructor(options: SearchFormClientOptions) {
    super(options);

    this.form_element?.setAttribute("data-collapsed", "true"); // collapsed on mobile

    this._toggleVisibilityCallback = function noop() {};
    if (!this.form_element) {
      return;
    }

    bindAll(this, "_handleOriginSelected", "_handleDestinationSelected");

    this._initToggleSearchVisibility();
    this._initForm();
    this._initOriginInput();
    this._initDestinationInput();
    this._initOutboundDateInput();
    this._initReturnDateInput();
    this._initPassengerInput();

    this._enableFormOnLoad();
  }

  /**
   * Attach an event listener for search form visibility toggle
   *
   * @param cb
   */
  public onToggleVisibility(cb: () => void) {
    this._toggleVisibilityCallback = cb;
  }

  public changeOutboundDate(date: string | null): void {
    this.window.store.dispatch(setOutboundDate(date));
  }

  private _initToggleSearchVisibility() {
    this.toggle_search_btn =
      this.window.document.querySelectorAll(".js-toggle-search");

    if (!this.toggle_search_btn.length) {
      return;
    }

    this.toggle_search_btn.forEach(item =>
      item.addEventListener("click", (e: Event) => {
        e && e.preventDefault();
        this._toggleSearchVisibility();

        const target = e.target as HTMLElement;
        const expanded = !this.form_element.getAttribute("data-collapsed")
          ? "true"
          : "false";

        // tracking
        this.tracker.asyncTrack(
          Events.toggledSearchVisibility(
            target.getAttribute("data-toggle-search") || "",
            expanded
          )
        );
      })
    );
  }

  protected override async _initOriginInput(): Promise<void> {
    const autocomplete_options: AutocompleteOptions = {
      append_to: "#origin-modal .js-suggestions-container",
      features: this.options.features,
      lang: this.options.lang,
      locale: this.options.locale,
      napi_url: this.options.napi_url,
      tracker: this.tracker,
      whitelabel: this.options.whitelabel,
      window: this.window,
      onPlaceSelected: this._handleOriginSelected,
      onSearchStarted: this.handleSearchStarted.bind(this),
      onSearchEnded: this.handleSearchEnded.bind(this)
    };

    if (this.state.latitude && this.state.longitude) {
      autocomplete_options.latitude = this.state.latitude;
      autocomplete_options.longitude = this.state.longitude;
    }

    this.origin_autocomplete = new AutocompleteModal(
      "origin",
      autocomplete_options
    );

    // Search nearby after we init the autocomplete to cache result in browser and improve performance
    if (!this._getSearchFormState().origin) {
      void this.origin_autocomplete?.loadInitialSuggestions();
    }

    this.openAutocompleteOnTouch(
      this.origin_city_input,
      this.origin_autocomplete,
      Events.clickedSearchInput("origin")
    );

    super._initOriginInput();
  }

  private openAutocompleteOnTouch(
    input: HTMLInputElement,
    autocomplete: undefined | AutocompleteModal,
    trackingEvent:
      | AmplitudeEvent<ClickedSearchOrigin>
      | AmplitudeEvent<ClickedSearchDestination>
  ): void {
    const openAutocomplete = async () => {
      await autocomplete?.setInputValue("");

      // Show triggers a repaint. It's better to do it asynchronously to improve our INP
      setTimeout(async () => {
        await autocomplete?.show();

        this.tracker.asyncTrack(trackingEvent);
      });
    };

    onFastClick(input, openAutocomplete);
  }

  protected override _initDestinationInput(): void {
    const autocomplete_options: AutocompleteOptions = {
      append_to: "#destination-modal .js-suggestions-container",
      features: this.options.features,
      lang: this.options.lang,
      locale: this.options.locale,
      napi_url: this.options.napi_url,
      tracker: this.tracker,
      whitelabel: this.options.whitelabel,
      window: this.window,
      onPlaceSelected: suggested_place => {
        return this._handleDestinationSelected(suggested_place);
      },
      onSearchStarted: this.handleSearchStarted.bind(this),
      onSearchEnded: this.handleSearchEnded.bind(this)
    };

    if (this.state.latitude && this.state.longitude) {
      autocomplete_options.latitude = this.state.latitude;
      autocomplete_options.longitude = this.state.longitude;
    }

    this.destination_autocomplete = new AutocompleteModal(
      "destination",
      autocomplete_options,
      { with_animation: true }
    );

    // Force the autocomplete creation to avoid a weird behavior of not focusing on the input when the origin is selected
    this.destination_autocomplete.getAutocomplete();

    this.openAutocompleteOnTouch(
      this.destination_city_input,
      this.destination_autocomplete,
      Events.clickedSearchInput("destination")
    );

    super._initDestinationInput();
  }

  protected _initPassengerInput(): void {
    onFastClick(this.passenger_input, () => {
      this.window.store.dispatch(
        openModal({
          type: "passengers_selector",
          input_element: this.passenger_input,
          close_existing: true,
          display_loading_placeholder: this.window.BB.device.is_mobile
        })
      );

      this.tracker.asyncTrack(Events.clickedSearchPassenger());
    });

    // Used to handle the change of Server-side rendered Passenger Input
    this._onStateChange(
      state => state.passengers,
      (_, passengers) => {
        this._handlePassengerInputChange.call(this, passengers);
      }
    );
  }

  public override async _handleOriginSelected(
    place: SearchPlace
  ): Promise<void> {
    super._handleOriginSelected(place);
    void this.destination_autocomplete?.loadInitialSuggestions();

    this.origin_city_input.classList.remove("has-errors");
    this.origin_city_input.value = getFormattedSuggestionName(
      placeToSuggestion(place)
    );

    this.tracker.asyncTrack(Events.selectedOrigin(place));

    if (!this.destination_city_input.value) {
      if (this.destination_autocomplete) {
        await this.destination_autocomplete.show();
      }
      this.tracker.asyncTrack(Events.openedSearchDestination());
    } else {
      if (this.origin_autocomplete) {
        this.origin_autocomplete.hide();
      }
    }
  }

  public override async _handleDestinationSelected(
    place: SearchPlace
  ): Promise<void> {
    super._handleDestinationSelected(place);

    this.destination_city_input.classList.remove("has-errors");
    if (this.destination_autocomplete) {
      this.destination_autocomplete?.hide();
    }
    this.destination_city_input.value = getFormattedSuggestionName(
      placeToSuggestion(place)
    );

    this.tracker.asyncTrack(Events.selectedDestination(place));
  }

  public override _handleSwapCities() {
    super._handleSwapCities();
    const origin_city_value = this.origin_city_input.value;
    this.origin_city_input.value = this.destination_city_input.value;
    this.destination_city_input.value = origin_city_value;
  }

  private _collapseSearch(): void {
    this.form_element.style.height = "0px";
    this.form_element.setAttribute("data-collapsed", "true");
  }

  private _expandSearch(): void {
    this.form_element.style.height = this.form_element.scrollHeight + "px";
    this.form_element.setAttribute("data-collapsed", "false");
  }

  private _toggleSearchVisibility(): void {
    const isCollapsed =
      this.form_element.getAttribute("data-collapsed") === "true";

    if (isCollapsed) {
      this._expandSearch();
    } else {
      this._collapseSearch();
    }
  }
}
