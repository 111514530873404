export function styleDsElement(element: HTMLElement) {
  const ds_textfield = element.closest("[data-dstype='DsTextField']");
  if (!ds_textfield) {
    return;
  }
  const ds_input = ds_textfield.querySelector("[data-dstype='DsInputBase']");
  const ds_label = ds_textfield.querySelector("[data-dstype='DsInputLabel']");

  if (!ds_input || !ds_label) {
    return;
  }

  const input_classmap = JSON.parse(
    ds_input.getAttribute("data-dsclassmap") || "{}"
  ) as { focused: string[] };
  const label_classmap = JSON.parse(
    ds_label.getAttribute("data-dsclassmap") || "{}"
  ) as { focused: string[] };

  if (!input_classmap || !label_classmap) {
    return;
  }

  element.addEventListener("focus", e => {
    e.stopPropagation();
    input_classmap.focused.forEach((className: string) => {
      ds_input.classList.add(className);
    });
    label_classmap.focused.forEach((className: string) => {
      ds_label.classList.add(className);
    });
  });

  element.addEventListener("blur", e => {
    e.stopPropagation();
    input_classmap.focused.forEach((className: string) => {
      ds_input.classList.remove(className);
    });
    label_classmap.focused.forEach((className: string) => {
      ds_label.classList.remove(className);
    });
  });
}
