export default class DomSelector {
  public constructor(private document: Document) {
    this.document = document;
  }

  public getElement(query: string, parent?: ParentNode | null): HTMLElement {
    if (!parent) {
      parent = this.document;
    }

    return parent.querySelector(query) || this._getNoopElement("div");
  }

  public getInputElement(query: string): HTMLInputElement {
    return this.document.querySelector(query) || this._getNoopElement("input");
  }

  private _getNoopElement<K extends keyof HTMLElementTagNameMap>(
    tag_name: K
  ): HTMLElementTagNameMap[K] {
    const element = this.document.createElement(tag_name);
    element.dataset.noopElement = "true";
    return element;
  }

  public isNoopElement(element: HTMLElement): boolean {
    return element.dataset.noopElement === "true";
  }
}
